<template>
  <div v-if="isDesktop" class="system">
      <div class="system-menu">
           <div class="option" @click="state='עדכון מערכת'" >
                <i class="material-icons">update</i>
                <p>עדכון מערכת</p>
            </div>
           <div class="option" @click="state='תמיכה'" >
                <i class="material-icons">support_agent</i>
                <p>תמיכה אונליין</p>
            </div>
           <div class="option" @click="state='יצירת קשר'" >
                <i class="material-icons">contacts</i>
                <p>טפסי יצירת קשר</p>
            </div>
           <div class="option" @click="state='דרושים'">
                <i class="material-icons">work_outline</i>
                <p>דרושים</p>
            </div>
          
      </div>
      <div class="system-main">
          <SystemUpdate v-if="state=='עדכון מערכת'"/>
          <SupportManagement v-if="state=='תמיכה'" />
          <ContactForms v-if="state=='יצירת קשר'" />
          <JobsMain v-if="state=='דרושים'" />
      </div>
  </div>
    <OnlyForDesktop v-if="!isDesktop" />
</template>

<script>
import { ref } from '@vue/reactivity'
import SystemUpdate from '../../components/Admin/System/SystemUpdate.vue'
import SupportManagement from '../../components/Admin/System/SupportManagement.vue'
import ContactForms from '../../components/Admin/System/ContactForms.vue'
import JobsMain from '../../components/Admin/System/jobs/JobsMain.vue'
import OnlyForDesktop from '../../components/Not_responsible/OnlyForDesktop.vue'
import { onMounted, onUnmounted } from '@vue/runtime-core'

export default {
    components:{SystemUpdate,SupportManagement,ContactForms,OnlyForDesktop,JobsMain},
    setup(){
        const state = ref(null)
        const isDesktop = ref(true)
        window.addEventListener('resize',()=>{
                if(window.innerWidth<770){
                    isDesktop.value = false
                }else{
                    isDesktop.value = true
                }
        })
        onMounted(()=>{
            if(window.innerWidth<770)isDesktop.value = false
            else isDesktop.value = true
        })
        onUnmounted(()=>{
             window.removeEventListener('resize',()=>{
                if(window.innerWidth<770){
                    isDesktop.value = false
                }else{
                    isDesktop.value = true
                }
            })
        })
        
      
        return{state,isDesktop}
    }
}
</script>

<style scoped>
    .system{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 375px calc(100% - 375px);
        grid-template-rows: 100%;
    }
    .system-menu{
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        overflow-y: auto;
        padding: 5px;
    }
    .system-main{
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
    .option{
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 26px;
        background: var(--secondary);
        padding: 0 5px 0 0;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .option:hover p{
        margin-right:25px;
        color: var(--yellow);
    }
    .option i {
        margin-left: 25px;
        color: var(--light);
        font-size: 33px;
    }
    .option p{
        color:var(--light);
        transition: 0.25s;
    }
    @media only screen and (max-width: 800px) {
        .shivuk-rosman{
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows:  150px calc(100% - 150px);
        }
    }
</style>