<template>
  <div class="support-managment">
      <div class="header">
          <h2>תמיכה טכנית</h2>
          <div class="minimize">
            <i @click="handle_minimize" class="material-icons">expand_less</i>
          </div>
      </div>
      <div class="support-chat-box">
          <div class="chat-screen">
              <template v-for="msg in sort_messages" :key="msg.msgId">
                <div v-if="msg.type=='supporter'" class="chat-line-service">
                    <p style="margin-left:10px; font-size:0.7rem;">{{msg.createdAt.toDate().toLocaleTimeString('he')}}</p>
                    <p style="font-weight:bold; margin-left:10px;">{{msg.name}}</p>
                    <p>{{msg.text_msg}}</p>
                </div>
                <div v-if="msg.type=='client'" class="chat-line-client">
                    <p style="margin-left:10px; font-size:0.7rem;">{{msg.createdAt.toDate().toLocaleTimeString('he')}}</p>
                    <p style="font-weight:bold; margin-left:10px;">{{msg.name}}</p>
                    <p v-if="msg.text_msg">{{msg.text_msg}}</p>
                    <div v-if="msg.image" @click="handle_open_image(msg.image)" class="image">
                        <img :src="msg.image">
                    </div>
                </div>
              </template>
          </div>
          <div class="chat-input">
            <el-input v-model="text_msg" @keypress.enter="handle_send_msg" style="width:84%; height:80% margin:0;" />
            <el-button @click="handle_send_msg" style="width:15%; height:65%; margin:0; flex-shrink: 0; background:#6C62E2;" icon="el-icon-share" type="success">שליחה</el-button>
          </div>
      </div>
      <div class="requests">
           <template v-for="(request,index) in requests" :key="request.user_uid">
                <div class="request" :id="`request-${index}`">
                    <div class="box date">
                        <p>תאריך</p>
                        <p>{{request.createdAt.toDate().toLocaleDateString('he')}}</p>
                    </div>
                    <div class="box time">
                        <p>זמן</p>
                        <p>{{request.createdAt.toDate().toLocaleTimeString('he')}}</p>
                    </div>
                    <div class="box name">
                        <p>שם</p>
                        <p>{{request.display_name}}</p>
                    </div>
                    <div class="box status">
                        <el-tag v-if="request.status=='pending'" type="warning" effect="dark">{{ 'ממתין' }}</el-tag>
                        <el-tag v-if="request.status=='in_session'" type="success" effect="dark">{{'בשיחה'}}</el-tag>
                        <el-tag v-if="request.status=='ended'" type="danger" effect="dark">{{'הסתיים'}}</el-tag>
                    </div>
                    <div class="box options">
                        <i @click="handle_start_chat(request,index)" class="material-icons msg">textsms</i>
                        <i @click="handle_end_session(request)" class="material-icons delete">delete</i>
                    </div>
                </div>
            </template>
      </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, onUpdated, ref } from '@vue/runtime-core'
import {projectFirestore} from '../../../firebase/config'
import {alert_confirm,alert} from '../../../Methods/Msgs'
export default {
    setup(){
        const requests = ref([])
        const messages = ref([])
        const request_unsub = ref(null)
        const messages_unsub = ref(null)
        const text_msg = ref('')
        const current_user_id = ref('')
        const sort_messages = ref(computed(()=>{
            if(messages.value.length){
                return messages.value.sort((a,b)=>{
                    return a.createdAt.toDate() - b.createdAt.toDate()
                })
            }
        }))
        
        const handle_open_image = (url)=>{
            window.open(url,'_blank')
        }
        
        const handle_send_msg = async()=>{
            if(text_msg.value && current_user_id.value){
                const doc = projectFirestore.collection('Chat').doc('Messages').collection(current_user_id.value).doc()
                await doc.set({
                   createdAt:new Date(),
                   msgId:doc.id,
                   name:'תומך',
                   text_msg:text_msg.value,
                   type:'supporter'
                })
                text_msg.value=''
            }
        }

        const get_requests_RT = ()=>{
            request_unsub.value = projectFirestore.collection('Chat').doc('Pending').collection('PendingRequests').onSnapshot(snapshot => {
                 snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        requests.value.push(change.doc.data())
                    }
                    if (change.type === "modified") {
                        const index = requests.value.findIndex(request=>request.user_uid==change.doc.data().user_uid)
                        if(index!=-1){
                            requests.value[index]=change.doc.data()
                        }
                    }
                    if (change.type === "removed") {
                        if(change.doc.data().user_uid == current_user_id.value){
                               alert('info','סיום שיחה',
                               `המשתמש ${change.doc.data().display_name} סיים את השיחה`)
                               .then(()=>{
                                   reset()
                               })
                        }
                        const index = requests.value.findIndex(request=>request.user_uid==change.doc.data().user_uid)
                        if(index!=-1){
                            requests.value.splice(index,1)
                        }
                    }
                })
            })

        }

        const handle_start_chat=async(request,index)=>{
            document.getElementsByClassName("marker").forEach(request=>request.classList.remove('marker'))
            document.getElementById(`request-${index}`).classList.add('marker')
            reset()
            if(request.status=='pending'){
                await change_status(request.user_uid,'in_session')
            }
            current_user_id.value=request.user_uid
            localStorage.setItem('support_chat',JSON.stringify({
                user_uid:current_user_id.value,
            }));
            get_messages_RT(request.user_uid)
        }
        const get_messages_RT = (user_uid)=>{
            messages_unsub.value = projectFirestore.collection('Chat').doc('Messages').collection(user_uid).onSnapshot(snapshot => {
                   snapshot.docChanges().forEach((change) => {
                       if (change.type === "added") {
                         messages.value.push(change.doc.data())  
                       }
                       if (change.type === "modified") {
                           const index = messages.value.findIndex(msg=>msg.msgId==change.doc.data().msgId)
                           if(index!=-1){
                               messages.value[index]=change.doc.data()
                           }
                       }
                       if (change.type === "removed") {
                           const index = messages.value.findIndex(msg=>msg.msgId==change.doc.data().msgId)
                           if(index!=-1){
                               messages.value.splice(index,1)
                           }
                       }
                   })
            })
        }
        
        const change_status=async(user_uid,status)=>{
            await projectFirestore.collection('Chat').doc('Pending')
            .collection('PendingRequests').doc(user_uid)
            .set({
                status
            },{merge:true})
        }

        const handle_minimize = ()=>{
            document.querySelector('.support-chat-box').classList.toggle("shrink");
            document.querySelector('.minimize').classList.toggle("rotate");
        }

        const reset=()=>{
            localStorage.removeItem('support_chat')
            messages.value = []
            current_user_id.value = ''
            if(messages_unsub.value){
                messages_unsub.value()
            }
        }

        const handle_end_session = (request)=>{
            alert_confirm(`האם לסיים התכתבות עם ${request.display_name}`)
            .then(async res=>{
                if(res.isConfirmed){
                    await change_status(request.user_uid,'ended')
                    reset()
                }
            })
        }

        onUpdated(()=>{
          document.querySelector('.chat-screen').scrollTop= document.querySelector('.chat-screen').scrollHeight;
        })
        
        onMounted(()=>{
            get_requests_RT()
            const local_data = JSON.parse(localStorage.getItem('support_chat'))
            if(local_data){
                current_user_id.value = local_data.user_uid
                get_messages_RT(current_user_id.value)
            }
        })

        onUnmounted(async()=>{
            if(messages_unsub.value){
                await messages_unsub.value()
            }
            if(request_unsub.value){
                await request_unsub.value()
            }
        })
        
        return{handle_open_image,sort_messages,handle_minimize,handle_end_session,handle_send_msg,text_msg,requests,messages,handle_start_chat,current_user_id}
    }
}
</script>

<style scoped>
    .support-managment{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #333;
    }
    .header{
        position: relative;
        color: #fff;
        width: 80%;
        height: 50px;
        background:#6C62E2;
        border-radius: 10px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header .minimize{
        position: absolute;
        width: 50px;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: transform 0.2s;
    }
    .header .minimize.rotate{
        transform: rotate(180deg);
    }
    .requests{
        width: 80%;
        height: calc(100% - 355px);
        overflow:hidden;
        overflow-y: auto;
    }
    .request{
        width:100%;
        height: 50px;
        background:#fff;
        margin-bottom: 5px;
        flex-shrink: 0;
        display: flex;
        border-radius: 20px;
        -webkit-box-shadow: 5px 5px 15px 5px #283046; 
        box-shadow: 5px 5px 15px 5px #283046;   

    }
    .box{
        width: 20%;
        height: 100%;
        font-size: 18px;
    }
    .date,.name,.status,.time{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .options{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    i{
        cursor: pointer;
    }
    .msg:hover{
        color: #6059CA;
    }
    .delete:hover{
        color: crimson;
    }
    .support-chat-box{
        width:80%;
        height: 300px;
        display: block;
    }
    .support-chat-box.shrink{
        display: none;
    }
    .chat-screen{
        padding: 5px;
        width: 100%;
        height: 80%;
        background-color: #fff;
        overflow:hidden;
        overflow-y: auto;
        border-radius: 10px;
    }
    .chat-input{
        padding: 5px;
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .chat-line-service{
        width: fit-content;
        padding: 5px;
        background-color: rgb(223, 221, 221);
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: center;
        border-radius: 10px;
    }
    .chat-line-client{
        width: fit-content;
        padding: 5px;
        background-color: rgba(97, 231, 255, 0.712);
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        align-items: center;
        overflow: hidden;
        border-radius: 10px;
    }
    .marker{
        background:#e7eaf6;
    }
    .image{
        width: 200px;
        height: 100px;
        cursor: pointer;
    }
    .image img{
        max-width: 100%;
        max-height: 100%;
    }
</style>